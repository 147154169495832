import React from 'react';
import { graphql, Link } from 'gatsby';
import Gallery from '@browniebroke/gatsby-image-gallery';
import Layout from '../../components/Layout';
import ScrollToTop from 'react-scroll-up';

const ChurchPhotos = ({ data, location }) => {
  const images = data.weddingPhotosPage.nodes.map((item) => {
    return {
      thumb: item?.file?.childImageSharp.thumb,
      full: item?.file?.childImageSharp.full,
    };
  });

  return (
    <Layout fullMenu location={location} title="Church Photos">
      <article id="main">
        <header className="photos-header">
          <h2>Church</h2>
          <p>
            Photographer:{' '}
            <a
              href="https://www.jonnyngo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jonny Ngo
            </a>
          </p>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <div className="box alt">
              <p>
                <Link to="/photos">&larr; Back to Photos</Link>
              </p>
              <div className="gallerya">
                <Gallery images={images} colWidth={100} mdColWidth={25} />
              </div>
            </div>
          </div>
        </section>
      </article>
      <ScrollToTop showUnder={160} duration={700}>
        <div className="button primary scroll-up-button">
          <span className="icon solid fa-arrow-up" />
        </div>
      </ScrollToTop>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    weddingPhotosPage: allGooglePhotosPhoto(
      filter: { album: { title: { eq: "Wedding - Church" } } }
    ) {
      nodes {
        file {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ChurchPhotos;
